.mi-tabs-container .tab-icon svg {
    font-size: 20px; /* Solo afecta a los iconos dentro de este componente */
  }

  .pie-chart-container {
    width: 100%;
    max-width: 600px;
    margin: auto;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.2s ease;
    overflow: hidden;
    cursor: pointer;
  }
  
  .pie-chart-container:hover {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
  
  .pie-chart-header {
    background-color: #116194;
    color: white;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding: 12px;
  }
  
  .pie-chart-content {
    padding: 20px;
    text-align: center;
  }
  
  .chart-container{
    width: 100%;
    max-width: 600px;
    margin: auto;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.2s ease;
    overflow: hidden;
    cursor: pointer;
  }
  
  .chart-container:hover {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
  
  .chart-header {
    background-color: #116194;
    color: white;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding: 12px;
  }
  
  .chart-content {
    padding: 20px;
    text-align: center;
  }


  .table-container-enfoque {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.2s ease;
  }
  
  .table-container-enfoque:hover {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
  

  .custom-table-enfoque {
    border-radius: 12px !important;
  }
  
  .custom-table-enfoque .ant-table {
    border-radius: 12px;
  }
  
  .custom-table-enfoque .ant-table-thead > tr > th {
    background-color: #042956;
    color: white;
    font-weight: bold;
    text-align: center;
  }
  
  .custom-table-enfoque .ant-table-tbody > tr > td {
    padding: 12px;
    font-size: 14px;
  }
  
  .custom-table-enfoque .ant-table-container {
    border-radius: 12px;
    overflow: hidden;
  }
 
  .ant-table-thead > tr > th.custom-header-enfoques {
    background-color: #49b6f6 !important;
    color: #ffffff !important;
  }