.ant-table-thead > tr > th.custom-header-sesiones {
    background-color: #0968c6 !important;
    color: #ffffff !important;
  }
  
  .ant-table-thead > tr > th.custom-header-participacion {
    background-color: #408cc8 !important;
    color: #FFF !important;
  }

  .ant-table-thead > tr > th.custom-header-participacion-sesionesV{
    background-color: #0b446f !important;
    color: #FFF !important;
  }

  .ant-table-thead > tr > th.custom-header-participacion-sesionesP{
    background-color: #500d7f !important;
    color: #FFF !important;
  }

  

  .ant-table-thead > tr > th.custom-header-participacion-sesionesA{
    background-color: #50adf3 !important;
    color: #FFF !important;
  }


  .ant-table-thead > tr > th.ajustecolumna {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  