/* 🎨 Estilos para el Drawer */
.custom-drawer-repositorio .ant-drawer-header {
  background: white !important; /* Fondo blanco para el encabezado del Drawer */
  color: black !important; /* Texto negro */
  padding: 1px 24px; /* Espaciado interno */
  
}

.custom-drawer-repositorio .ant-drawer-close {
  color: red !important; /* Botón de cierre en rojo */
}

.custom-drawer-repositorio .ant-drawer-body{

  padding-top: 0px !important;
  padding-left: 0px !important;
}

/* 📌 Barra de herramientas */
.file-toolbar-repositorio {
  display: flex; /* Elementos en línea */
  flex-wrap: wrap; /* Permite que los elementos se ajusten */
  justify-content: center; /* Centra los elementos horizontalmente */
  align-items: center; /* Alineación vertical */
  gap: 12px; /* Espaciado entre elementos */
  width: 100%; /* Ocupa todo el ancho */
  padding: 10px 0; /* Espaciado interno */
}

/* 📌 Estilos para botones */
.file-toolbar-button-repositorio,
.file-toolbar-upload-repositorio,
.file-toolbar-close-repositorio {
  height: 46px !important; /* Altura uniforme */
  font-size: 14px; /* Tamaño de fuente */
  padding: 0 16px; /* Espaciado interno */
  border-radius: 6px; /* Bordes redondeados */
  transition: all 0.3s ease-in-out; /* Transición suave */
}

/* Botón "Subir" - Fondo negro */
.file-toolbar-upload-repositorio {
  background-color: #1c7cbd !important; /* Fondo negro */
  color: white !important; /* Texto blanco */
  border: none !important; /* Sin borde */
}

.file-toolbar-upload-repositorio:hover {
  background-color: #333 !important; /* Fondo más oscuro al pasar el cursor */
}

/* Botón "Cerrar" - Rojo */
.file-toolbar-close-repositorio {
  background-color: red !important; /* Fondo rojo */
  color: white !important; /* Texto blanco */
  border: none !important; /* Sin borde */
}

.file-toolbar-close-repositorio:hover {
  background-color: darkred !important; /* Fondo más oscuro al pasar el cursor */
}

/* Botón genérico - Fondo blanco */
.file-toolbar-button-repositorio {
  background-color: white !important; /* Fondo blanco */
  border: 1px solid #d9d9d9 !important; /* Borde gris */
  color: black !important; /* Texto negro */
}

.file-toolbar-button-repositorio:hover {
  background-color: #f0f0f0 !important; /* Fondo gris claro al pasar el cursor */
}

/* 📌 Estilos para iconos de búsqueda y filtro */
span.anticon.anticon-filter.my-filter-icon-repositorio-file-toolbar svg,
span.ant-input-group-wrapper.ant-input-group-wrapper-outlined.css-dev-only-do-not-override-tjsggz.ant-input-search.file-toolbar-search-repositorio svg {
  color: #000000; /* Iconos en negro */
}

/* 📌 Input de búsqueda */
.file-toolbar-search-repositorio input.ant-input {
  height: 46px !important; /* Altura uniforme */
  font-size: 14px; /* Tamaño de fuente */
  border-radius: 6px; /* Bordes redondeados */
}

/* Botón de búsqueda */
.file-toolbar-search-repositorio .ant-input-search-button {
  height: 46px !important; /* Altura uniforme */
}

/* Asegurar altura uniforme en botones */
.file-toolbar-repositorio button.ant-btn {
  height: 46px !important; /* Altura uniforme */
}

/* 📌 Responsividad: Ajustes para móviles */
@media (max-width: 768px) {
  .file-toolbar-repositorio {
    flex-direction: column; /* Cambia a columna en pantallas pequeñas */
    gap: 8px; /* Reduce el espaciado */
  }

  .file-toolbar-button-repositorio,
  .file-toolbar-upload-repositorio,
  .file-toolbar-close-repositorio {
    width: 100%; /* Botones ocupan todo el ancho */
  }

  .file-toolbar-search-repositorio {
    width: 100%; /* Input de búsqueda ocupa todo el ancho */
  }
}

/* 📌 Divisor */
.divider-repositorio {
  border-top: 1px solid #ddd; /* Línea divisoria */
  margin: 8px 0; /* Espaciado vertical */
}

/* 📌 Contenedor de radios */
.radio-container-repositorio {
  display: block; /* Elemento en bloque */
  margin-top: 8px; /* Espaciado superior */
}

.radio-group-repositorio {
  display: flex; /* Elementos en línea */
  flex-direction: column; /* Alineación en columna */
  gap: 5px; /* Espaciado entre radios */
}

/* 📌 Contenedor principal */
.container-repositorio {
  display: flex; /* Elementos en línea */
  height: 100vh; /* Altura completa de la ventana */
}

/* 📌 Menú lateral */
.menu-repositorio {
  width: 20%; /* Ancho del menú */
  background-color: #fdfdfd; /* Fondo claro */
  color: white; /* Texto blanco */
}

/* 📌 Contenido principal */
.content-repositorio {
  width: 100%; /* Ancho del contenido */
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px; /* Espaciado interno */
  background-color: #ffffff; /* Fondo blanco */
  border-left: 2px solid #f3f0f0; /* Borde izquierdo */
}

/* 📌 Ajustes para el cuerpo del Drawer */
.ant-drawer-body {
  padding: 0px; /* Sin espaciado interno */
}
/*   */
span.anticon.anticon-filter svg {
  color: #000000;
}





.table-responsive-name td:nth-child(2) { /* Segunda columna (Nombre) */
  max-width: 300px; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 768px) { /* Para pantallas pequeñas */
  .table-responsive-name td:nth-child(2) {
    max-width: 150px; /* Limita el ancho en pantallas pequeñas */
  }
}
/* Iconos de archivos */
.table-responsive-name .icon-file {
  color: #007bff; /* Azul para archivos */
}

.table-responsive-name .icon-global {
  color: #28a745; /* Verde para URLs o enlaces globales */
}

/* Icono de favoritos */
.table-responsive-name .icon-favorite {
  color: #ffc107; /* Amarillo cuando está marcado como favorito */
}

/* Icono de descargar */
.table-responsive-name .icon-download {
  color: #007bff; /* Celeste para descargas */
}

/* Icono de eliminar */
.table-responsive-name .icon-delete {
  color: #dc3545; /* Rojo para eliminar */
}

/* Ajuste al pasar el mouse sobre los botones */
.table-responsive-name .icon-button:hover {
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;
}

/* Aplica estilos solo dentro del contenedor con la clase .selected-items-toolbar */
.selected-items-toolbar .icon-file {
  color: #0f0f10; /* Gris oscuro */
}

.selected-items-toolbar .icon-download {
  color: #1f6ddb;
}

.selected-items-toolbar .icon-delete {
  color: #dc2626; /* Rojo */
}


/* Para cambiar el color del item seleccionado (folder) en el menú lateral */

:where(.ant-menu:not(.ant-menu-horizontal)) :where(.ant-menu-submenu-title-selected) {
  background-color: var(--color-success) !important; 
}

/* O para los items seleccionados (cuando no es SubMenu) */
:where(.ant-menu-item-selected) {
  background-color: var(--color-success) !important;
}

.custom-drawer-repositorio .ant-drawer-header{
  background-color: #1677ff !important;
  padding-top: 0px;
}