@import "~bootstrap/dist/css/bootstrap.min.css";
/* index.css */
@import 'leaflet/dist/leaflet.css';



/* src/global.css */
html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

:root {
    --color-primary: #0968c6; /* Azul Bootstrap */
    --color-secondary: #6c757d; /* Gris Bootstrap */
    --color-success: #28a745; /* Verde Bootstrap */
    --color-danger: #dc3545; /* Rojo Bootstrap */
    --color-warning: #ffc107; /* Amarillo Bootstrap */
    --color-info: #328bc7; /* Cian Bootstrap */
    --color-light: #dee3eb; /* Blanco grisáceo Bootstrap */
    --color-dark: #343a40; /* Negro grisáceo Bootstrap */

    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.main-content {
  flex: 1;
}

main {
  flex: 1;
}

body {
    font-family: 'Arial', sans-serif;
    background-color: var(--color-light) !important;
    color: var(--color-dark);
}

.App {
  text-align: center;
}

.App-logo {
  height: 330vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
