:where(.css-dev-only-do-not-override-1gwfwyx).ant-table-wrapper table {
  width: -1% ; 
 text-align: start;
 border-radius: 8px 8px 0 0;
 border-collapse: separate;
 border-spacing: 0;
}


/* 1. Asegurar que todas las filas inician con fondo blanco */
.ant-table-tbody > tr {
 background: white !important;
}

/* 2. Hover en color azul claro para todas las filas, incluyendo filas combinadas */
.ant-table-tbody > tr:hover,
.ant-table-tbody > tr:hover .ant-table-cell {
 background: #b3d5f4 !important;
}

/* 3. Hover en celdas individuales */
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
 background: #b3d5f4 !important;
}


.tablaAcompanamiento {
  table-layout: auto !important;
}

/* Contenedor de la tabla con scroll */
.tablaAcompanamiento .ant-table-container {
  max-height: 800px; /* Altura deseada */
  overflow-y: auto; /* Scroll solo en el cuerpo */
}

/* Fijar encabezado */
.tablaAcompanamiento .ant-table-thead {
  position: sticky;
  top: 0;
  background: white;
  z-index: 2; /* Asegura que esté por encima de las filas */
}


/* Aplica esto a las celdas de la tabla */
/* Estilo para la celda de la tabla */
.tematica-col {
  min-width: 500px !important;
  max-width: 500px !important;
  width: 500px !important;
}

/* Ajuste del contenido dentro de la celda */
.tematica-content {
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Número máximo de líneas antes de cortar */
  line-clamp: 4; /* Número máximo de líneas antes de cortar */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%; /* Ajusta la altura máxima según lo necesites */
  line-height: 20px; /* Ajusta el espaciado entre líneas */
  width: 100%;
  text-align: justify;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

