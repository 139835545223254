.bgCard{
    background-color: #f5f5dc;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
}

.bgCardInterno{
    background-color: #f5f5dc;
    padding: 0px;
}

.bgCardInternoGraficos{
    background-color: #ffffff;
    padding: 0px;

}

.bgCardInternoGraficos:hover{
    background-color: #f8f9fa;
    padding: 0px;

}

.bgCardInterno:hover{
    background-color: #f8f9fa;
    
}

.titleFont{
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
}

.colorTitle{
    color: #02162f;
}