.TablaHover tbody tr:hover {
    background-color: #f5f5f5; /* O el color que prefieras */
  }

  .titleResumen{
    color: #042956 !important;
    font-weight: bold !important; 
  }

  :where(.icono-lupa) {
    font-size: 18px !important;
    color: #1677ff !important;
    cursor: pointer;
}

.span.anticon.anticon-search{
  color: #1677ff !important;

}


.icono-lupa svg {
  font-size: 18px !important;
  color: #1677ff !important;
}


.icono-lupa:hover svg {
  
  color: #28a745 !important; /* Cambia el color al pasar el mouse */
}


:where(.resaltado) td{
  background-color: #d5d5d5 !important; /* Amarillo claro */
  color: #0b0b0b !important;
  
  font-weight: bold !important;
  cursor: pointer;
}

.chart-container {
  height: 500px; /* O ajusta según necesidad */
}